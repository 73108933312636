.item_icon[data-v-c2ffa43c] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-c2ffa43c] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon .iconfont icon-cuowu[data-v-c2ffa43c] {
  color: #f56c6c;
}
.item_icon .iconfont icon-duihao[data-v-c2ffa43c] {
  color: #67c23a;
}
.item_icon .el-icon-circle-check[data-v-c2ffa43c] {
  color: #67c23a;
}
.item_icon .iconfont icon-info[data-v-c2ffa43c] {
  color: #409eff;
}
.item_icon .iconfont icon-cuowu[data-v-c2ffa43c] {
  color: #e6a23c;
}
.item_icon .iconfont icon-cuowu-outline[data-v-c2ffa43c] {
  color: #e6a23c;
}
.item_icon p[data-v-c2ffa43c] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.iconfont[data-v-c2ffa43c] {
  font-size: 13px;
}
li[data-v-c2ffa43c] {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
li span[data-v-c2ffa43c] {
  margin-left: 5px;
}
li[data-v-c2ffa43c]:hover {
  color: #17a2b8;
}