.top[data-v-221a9933] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.w120[data-v-221a9933] {
  width: 120px;
}
.hint[data-v-221a9933] {
  color: var(--themeColor, #17a2b8);
  font-size: 14px;
  margin: 10px 0;
}
.foot[data-v-221a9933] {
  margin: 10px 0;
}
.foot > div[data-v-221a9933] {
  float: right;
}
.text_box[data-v-221a9933] {
  text-align: right;
  margin-bottom: 10px;
}