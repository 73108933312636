.keep-status-box .iconfont {
  font-size: 13px;
}
.login-tax-bureau-opera-get-auth-link li {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.login-tax-bureau-opera-get-auth-link li span {
  margin-left: 5px;
}
.login-tax-bureau-opera-get-auth-link li:hover {
  color: #17a2b8;
}