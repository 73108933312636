.classPrompt .el-message-box__container {
  color: red;
}
.login-tax-bureau-opera li {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.login-tax-bureau-opera li span {
  margin-left: 5px;
}
.login-tax-bureau-opera li:hover {
  color: #17a2b8;
}
